import { useState } from 'react'
import { useRouter } from 'next/router'
import clsx from 'clsx'

import { PAGE_ROUTES } from '~/constants'
/*import SearchInput from '~/components/SearchInput/SearchInput'*/
import Text from '~/components/Text/Text'
import Link from '~/components/Link/Link'
import Logo from '~/components/Logo/Logo'
import Button from '~/components/Button/Button'
import Icon from '~/components/Icon/Icon'
import Count from '~/components/Count/Count'
import SocialIcon from '~/components/SocialIcon/SocialIcon'
import EarlyAccessBadge from '~/components/EarlyAccessBadge/EarlyAccessBadge'
import { BadgeVariant } from '~/components/EarlyAccessBadge/types'
import { FetchedLayoutProps } from '~/lib/layout'

import { createNavigationItems } from './createNavigationItems'
import MobileMenu from './MobileMenu'
import styles from './Navigation.module.sass'

const isActivePage = (linkHref: string, currentPath: string) => {
  const overwrites: Partial<Record<PAGE_ROUTES, PAGE_ROUTES>> = {
    // current path: active menu link overwrites
    [PAGE_ROUTES.DOWNLOAD]: PAGE_ROUTES.DESKTOP,
  }

  currentPath = overwrites[currentPath as PAGE_ROUTES] || currentPath

  return currentPath.startsWith(linkHref)
}

type Props = FetchedLayoutProps

const Navigation: React.FC<Props> = ({ jobsCount, blogCount }) => {
  const router = useRouter()
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false)
  const { links, icons } = createNavigationItems({ jobsCount, blogCount })

  const toggleMobileMenu = () => setShowMobileMenu((prevState) => !prevState)

  const linksList = (
    <ul className={styles.links}>
      {links.map(({ label, href, external, count, earlyAccessBadge = false }) => (
        <li key={label}>
          <Text fontSize={18}>
            <Link
              href={href}
              external={external}
              data-track={`nav:go-to-${href.replace('/', '')}`}
              className={clsx(styles.link, { [styles.linkActivePage]: isActivePage(href, router.pathname) })}
            >
              {label}
              {count && <Count count={count} className={styles.count} />}
              {earlyAccessBadge && !isActivePage(href, router.pathname) && (
                <EarlyAccessBadge
                  variant={BadgeVariant.ICON}
                  className={styles.earlyAccessBadge}
                  textClassName={styles.earlyAccessBadgeText}
                />
              )}
            </Link>
          </Text>
        </li>
      ))}
    </ul>
  )

  const socialIcons = (
    <ul className={styles.socialIcons}>
      {icons.map((item) => (
        <li key={item.icon}>
          <SocialIcon {...item} trackLabel='from-nav' />
        </li>
      ))}
    </ul>
  )

  const menuIcon = (
    <div
      className={styles.featuredIconCircle}
      onClick={toggleMobileMenu}
      role='button'
      tabIndex={0}
      data-track='nav:toggle-nav'
    >
      <Icon name='menu' className={styles.featuredIcon} />
    </div>
  )

  return (
    <>
      <nav className={clsx(styles.navigation, 'site-container')}>
        <div className='flex items-center'>
          <Link
            href={PAGE_ROUTES.HOME}
            className={clsx(styles.logo, { [styles.logoNonClickable]: router.pathname == PAGE_ROUTES.HOME })}
            data-track='nav:logo-click'
          >
            <Logo width={140} height={40} className='hidden md:block' />
            <Logo width={92} height={26} className='block md:hidden' />
          </Link>

          <div className='hidden xl:flex'>{linksList}</div>
        </div>

        <div className='flex items-center'>
          <div className='hidden xl:flex'>{socialIcons}</div>

          {/*<SearchInput />*/}

          <div className='hidden xl:flex'>
            <Button
              variant='primary'
              className={styles.ctaButton}
              link={PAGE_ROUTES.APP}
              data-track='cta:go-to-app:from-nav'
            >
              Go to App →
            </Button>
          </div>

          <div className='flex xl:hidden'>{menuIcon}</div>
        </div>
      </nav>

      <div className='xl:hidden'>
        <MobileMenu show={showMobileMenu} toggle={toggleMobileMenu} navigationItems={{ links, icons }} />
      </div>
    </>
  )
}

export default Navigation
