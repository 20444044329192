import React, { useEffect } from 'react'
import clsx from 'clsx'

import Link from '~/components/Link/Link'
import Text from '~/components/Text/Text'
import Logo from '~/components/Logo/Logo'
import Button from '~/components/Button/Button'
import Icon from '~/components/Icon/Icon'
import Count from '~/components/Count/Count'
import SocialIcon from '~/components/SocialIcon/SocialIcon'
import EarlyAccessBadge from '~/components/EarlyAccessBadge/EarlyAccessBadge'
import { BadgeVariant } from '~/components/EarlyAccessBadge/types'
import { PAGE_ROUTES } from '~/constants'

import styles from './Navigation.module.sass'
import { NavigationItems } from './types'

interface Props {
  show: boolean
  toggle: () => void
  navigationItems: NavigationItems
}

const MobileMenu: React.FC<Props> = ({ show, toggle, navigationItems }) => {
  const { links, icons } = navigationItems

  useEffect(() => {
    document.body.style.overflow = show ? 'hidden' : 'unset'
  }, [show])

  return (
    <div className={clsx(styles.mobileMenu, show && styles.mobileMenuShown)}>
      <Link href={PAGE_ROUTES.HOME} onSamePageClick={toggle} data-track='nav:logo-click:from-mobile-nav'>
        <Logo className={styles.logo} width={92} height={26} />
      </Link>

      <div onClick={toggle} role='button' tabIndex={0}>
        <Icon name='close' className={styles.closeIcon} />
      </div>

      <ul className={styles.mobileLinks}>
        {links.map(({ href, label, count, earlyAccessBadge }) => (
          <li key={label}>
            <Text fontSize={28} className={styles.mobileLink}>
              <Link
                href={href}
                onSamePageClick={toggle}
                data-track={`nav:go-to-${href.replace('/', '')}:from-mobile-nav`}
              >
                {label}
                {count && <Count count={count} className={styles.count} />}
                {earlyAccessBadge && (
                  <EarlyAccessBadge
                    variant={BadgeVariant.ICON}
                    className={styles.earlyAccessBadge}
                    textClassName={styles.earlyAccessBadgeText}
                  />
                )}
              </Link>
            </Text>
          </li>
        ))}
      </ul>

      <footer className={styles.mobileMenuFooter}>
        <ul className={styles.mobileSocialIcons}>
          {icons.map((item) => (
            <li key={item.icon}>
              <SocialIcon {...item} trackLabel='from-mobile-nav' />
            </li>
          ))}
        </ul>

        <Button
          variant='primary'
          className={styles.mobileCtaButton}
          link={PAGE_ROUTES.APP}
          data-track='cta:go-to-app:from-mobile-nav'
        >
          Go to App →
        </Button>
      </footer>
    </div>
  )
}

export default MobileMenu
