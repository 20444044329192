import React from 'react'
import clsx from 'clsx'

import Icon from '~/components/Icon/Icon'
import Link from '~/components/Link/Link'

import { ButtonProps } from './types'
import styles from './Button.module.css'

/**
 * General purpose component for buttons.
 *
 * @param variant default: secondary, set typography variant
 * @param icon set button icon, will be rendered on the left
 * @param counter set button counter, will be rendered on the right
 */
export const Button: React.FC<ButtonProps> = ({
  variant = 'secondary',
  subVariant,
  icon,
  count,
  countTitle='',
  link,
  external = false,
  forceDarkMode = false,
  className,
  children,
  ...props
}) => {
  if (!children) {
    return null
  }

  const Button = ({ className }: { className?: string }) => (
    <button
      className={clsx(
        styles.button,
        styles[variant],
        subVariant && styles[subVariant],
        forceDarkMode && styles.forceDarkMode,
        className
      )}
      {...props}
    >
      {icon && (
        <div className={clsx(styles.iconWrapper, styles[`iconWrapper-${icon}`])}>
          <Icon name={icon} />
        </div>
      )}
      {children}
      {count && variant === 'counter' && (
        <div className={styles.counterElement} title={countTitle}>
          <div className={styles.counterElementText}>{count.toLocaleString()}</div>
        </div>
      )}
    </button>
  )

  if (link) {
    return (
      <Link className={clsx(styles.link, className)} href={link} external={external}>
        <Button />
      </Link>
    )
  }

  return <Button className={className} />
}

export default Button
