import { GITHUB_ORG_URL, PAGE_ROUTES, TWITTER_COMPANY_PROFILE_URL } from '~/constants'

import { NavigationItemProps, NavigationIconProps, NavigationOptions, NavigationItems } from './types'

export const createNavigationItems = (
  { jobsCount }: NavigationOptions = {
    jobsCount: undefined,
    blogCount: undefined,
  }
): NavigationItems => {
  const links: NavigationItemProps[] = [
    {
      label: 'Desktop',
      href: PAGE_ROUTES.DESKTOP,
    },
    {
      label: 'Terminal',
      href: PAGE_ROUTES.CLI,
    },
    {
      label: 'AI',
      href: PAGE_ROUTES.AI,
    },
    {
      label: 'Docs',
      href: PAGE_ROUTES.DOCS,
    },
    {
      label: 'Jobs',
      href: PAGE_ROUTES.JOBS,
      count: jobsCount,
    },
    {
      label: 'Blog',
      href: PAGE_ROUTES.BLOG,
      // NOTE: Count removed until amount of posts will be compromised.
      // count: blogCount,
    },
  ]

  const icons: NavigationIconProps[] = [
    {
      title: 'Twitter',
      icon: 'twitter',
      href: TWITTER_COMPANY_PROFILE_URL,
      external: true,
    },
    {
      title: 'GitHub',
      icon: 'github',
      href: GITHUB_ORG_URL,
      external: true,
    },
    {
      title: 'Discord',
      icon: 'discord',
      href: PAGE_ROUTES.DISCORD,
      external: true,
    },
  ]

  return { links, icons }
}
