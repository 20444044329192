import clsx from 'clsx'

import Text from '~/components/Text/Text'
import Link from '~/components/Link/Link'

import { BadgeProps, IconBadgeProps, LabelBadgeProps, isLabelBadge } from './types'
import styles from './EarlyAccessBadge.module.sass'

const IconBadge: React.FC<IconBadgeProps & { fontSize?: number | number[] }> = ({
  className,
  textClassName,
  pingAnimation = false,
  fontSize = 12,
  ...props
}) => (
  <span className={clsx(styles.pill, pingAnimation && styles.pingAnimation, className)} {...props}>
    <Text as='span' fontSize={fontSize} className={clsx(styles.pillText, textClassName)}>
      β
    </Text>
  </span>
)

const LabelBadge: React.FC<LabelBadgeProps> = ({
  children,
  className,
  textClassName,
  pingAnimation = false,
  link,
}) => {
  const badge = (
    <div className={clsx(styles.badge, className)}>
      <IconBadge fontSize={[14, 12, 14]} pingAnimation={pingAnimation} />
      <Text as='div' fontSize={[14, 12, 14]} className={clsx(styles.text, textClassName)}>
        {children}
      </Text>
    </div>
  )
  return link ? <Link href={link}>{badge}</Link> : badge
}

const EarlyAccessBadge: React.FC<BadgeProps> = (props) => {
  if (isLabelBadge(props)) {
    return <LabelBadge {...props} />
  } else {
    return <IconBadge {...props} />
  }
}

export default EarlyAccessBadge
