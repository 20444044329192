import { ButtonHTMLAttributes } from 'react'

export enum BadgeVariant {
  ICON = 'compact',
  LABEL = 'cta'
}

type BaseBadgeProps = {
  className?: string
  textClassName?: string
  pingAnimation?: boolean
}

type ButtonAttributes = ButtonHTMLAttributes<HTMLButtonElement>
export type IconBadgeProps = BaseBadgeProps & {
  variant?: BadgeVariant.ICON
}
export type LabelBadgeProps = BaseBadgeProps & ButtonAttributes & {
  variant?: BadgeVariant.LABEL,
  link?: string
}
export type BadgeProps = IconBadgeProps | LabelBadgeProps

export const isLabelBadge = (value: IconBadgeProps | LabelBadgeProps): value is LabelBadgeProps => {
  return value.variant === BadgeVariant.LABEL
}
